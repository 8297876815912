import isProduction from '@/utils/isProduction';
import { useTranslations } from 'next-intl';
import NL from './nl';
import EN from './en';

export const SUPPORTED_LOCALES: TranslationLocale[] = isProduction()
    ? ['nl']
    : ['nl', 'en'];
export const DEFAULT_LOCALE: TranslationLocale = 'nl';

export type TranslationLocale = 'nl' | 'en' | string;

type TranslationTreeValue = string | object;
type TranslationTreeEntry = Record<string, TranslationTreeValue>;

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type AdditionalTranslation = DeepPartial<Translation>;

const DEFAULT_LOCALE_TRANSLATIONS: Translation = NL;

export const getTranslations = (locale: TranslationLocale): Translation => {
    const baseTranslations = DEFAULT_LOCALE_TRANSLATIONS;

    switch (locale) {
        case 'en':
            return addKeysAndValues(baseTranslations, EN) as Translation;
        default:
            return baseTranslations;
    }
};

const addKeysAndValues = (
    baseObject: TranslationTreeEntry,
    additionalObject: TranslationTreeEntry,
) => {
    Object.keys(additionalObject).forEach((key) => {
        if (baseObject[key] && typeof baseObject[key] === 'object') {
            addKeysAndValues(
                baseObject[key] as TranslationTreeEntry,
                additionalObject[key] as TranslationTreeEntry,
            );
        } else {
            baseObject[key] = additionalObject[key];
        }
    });

    return baseObject;
};

export const useStrings = useTranslations;

export const useServerTranslations = (
    locale: TranslationLocale,
    keyBasePath: string,
) => {
    const translations = getTranslations(locale);

    return (keyPath: string): string =>
        [keyBasePath, keyPath]
            .join('.')
            .split('.')
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .reduce((acc, cur) => acc && acc[cur], translations as string);
};

export type Translation = {
    blocks: {
        QuizBlock: {
            title: string;
            avatar_of: string;
            character_is_right: string;
            character_is_wrong: string;
            picked_answer_label: string;
        };
    };
    components: {
        AccessibilityMenu: {
            preview_chat_message: string;
            open_button: string;
            section_general: string;
            option_dyslexia_font: string;
            option_increased_text_size: string;
            option_static_chat_timeline: string;
            section_screenreaders: string;
            option_screenreader_announcements: string;
            option_screenreader_announcements_info: string;
            option_restart: string;
            option_restart_title: string;
            option_restart_confirmation: string;
        };
        AccessibleTimeline: {
            next_button: string;
            finished_button: string;
        };
        CharacterNotification: {
            open_button: string;
            avatar_alt: string;
            close_button: string;
            title: string;
        };
        PageNavigationButtons: {
            navigation_label: string;
            back: string;
            forward: string;
        };
        StoryIntro: {
            intro_chat_message: string;
            begin_button: string;
        };
        SubjectProgress: {
            meter_label: string;
        };
    };
    doen: {
        FantasieMachine: {
            title: string;
            description: string;
            intro_chat_message: string;
            loading_chat_message: string;
            finished_chat_message: string;
            error_missing_category: string;
            error_chat_message: string;
            create_image: string;
            retry: string;
            retry_options: {
                question: string;
                image: string;
                story: string;
            };
            download: string;
            create_story: string;
            create_story_chat_message: string;
            categories: {
                subject: string;
                action: string;
                location: string;
                style: string;
            };
            category_placeholders: {
                subject: string;
                action: string;
                location: string;
                style: string;
            };
            category_prefixes: {
                subject: string;
                action: string;
                location: string;
                style: string;
            };
            category_values: {
                hippo: string;
                strawberry: string;
                snowman: string;
                teddy_bear: string;
                donut: string;
                unicorn: string;
                spaceship: string;
                sunflower: string;
                tutu: string;
                bow_tie: string;
                upside_down: string;
                dancing: string;
                reading: string;
                smiling: string;
                train_conductor: string;
                magic_wand: string;
                wings: string;
                soccer: string;
                on_vacation: string;
                superhero: string;
                stilts: string;
                office: string;
                island: string;
                moon: string;
                mars: string;
                beach: string;
                under_water: string;
                magical_forest: string;
                train: string;
                trampoline: string;
                castle: string;
                painting: string;
                picasso: string;
                black_and_white: string;
                drawing: string;
                three_dimensional: string;
                cartoon: string;
                watercolor: string;
                pop_art: string;
                photo: string;
            };
            VerhalenVerzinner: {
                title: string;
                description: string;
                intro_chat_message: string;
                loading_chat_message: string;
                error_chat_message: string;
                create_story: string;
                open_hint: string;
                share: string;
                close: string;
            };
        };
        StijlSwap: {
            title: string;
            description: string;
            intro_chat_message: string;
            loading: string;
            style: string;
            styles: {
                udnie: string;
                de_schreeuw: string;
                golf_van_kanagawa: string;
            };
            StijlSwapPhotoPicker: {
                user_picture_alt: string;
                take_picture: string;
                camera: string;
                camera_alt: string;
                camera_error: string;
                cancel: string;
            };
            StijlSwapResult: {
                open: string;
                title: string;
                result_alt: string;
                save: string;
                close: string;
            };
        };
    };
};
